import React from 'react';
import { useSelector } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import AppRouter from './AppRouter';
import { ErrorBoundary, FileViewerModal, Loader, SessionPopup } from './components/shared';

function App({ instance }) {
    // isLoaderRequired is false by default
    const isLoaderRequired = useSelector((state) => state.loader);
    const sessionPopup = useSelector((state) => state.sessionPopup);
    const openFileViewerModal = useSelector(state => state.fileViewerModal.openFileViewerModal);

    return (
        <MsalProvider instance={instance}>
            <ErrorBoundary>
                <AppRouter instance={instance} />
                {isLoaderRequired && <Loader />}
                {sessionPopup && <SessionPopup />}
                {openFileViewerModal && <FileViewerModal />}
            </ErrorBoundary>
        </MsalProvider>
    );
}

export default App;
