import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { setLoaderVisibility } from '../../../redux';
import { createNewsFeed, popup, updateNewsFeed } from '../../../utils';
import { NEWS_FEED, VALIDATION, SUCCESS } from '../../../constants';

export const NewsFeedAddEdit = ({
    openEditModal,
    setOpenEditModal,
    data,
    isEdit,
    debouncedApiCall,
    accountId,
    order,
}) => {
    const dispatch = useDispatch();
    const [feed, setFeed] = useState({ feeds: '' });
    const [validationError, setValidationError] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        if (openEditModal && isEdit && data) {
            setFeed(data);
        } else {
            setFeed({ feeds: '' });
        }
        if (openEditModal && inputRef.current) {
            inputRef.current.focus();
        }
    }, [openEditModal, data, isEdit]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'feeds' && value.length > 100) {
            setValidationError(VALIDATION.FEED_LENGTH_LIMIT);
        } else {
            setValidationError('');
            setFeed((prevData) => ({ ...prevData, [name]: value }));
        }
    };

    const handleClick = async () => {
        if (!feed.feeds) {
            popup('error', VALIDATION.EMPTY_FEED);
            return;
        }
        setIsDisabled(true);
        try {
            dispatch(setLoaderVisibility(true));
            if (isEdit) {
                try {
                    await updateNewsFeed({ id: data?.id, data: feed });
                    setOpenEditModal(false);
                    debouncedApiCall();
                    popup(
                        SUCCESS.SUCCESS_TOAST,
                        NEWS_FEED.UPDATED_MESSAGE
                    );
                } catch (error) {
                    setIsDisabled(false);
                    popup('error', error.message);
                    dispatch(setLoaderVisibility(false));
                }
            } else {
                try {
                    order &&
                        (await createNewsFeed({
                            data: { ...feed, account_id: accountId, order },
                        }));
                    setOpenEditModal(false);
                    debouncedApiCall();
                    popup(
                        SUCCESS.SUCCESS_TOAST,
                        NEWS_FEED.CREATED_MESSAGE
                    );
                } catch (error) {
                    setIsDisabled(false);
                    popup('error', error.message);
                    dispatch(setLoaderVisibility(false));
                }
            }
        } catch (error) {
            setIsDisabled(false);
            popup('error', error.message);
        }
        setIsDisabled(false);
    };

    return (
        <div
            className={`modal custom-modal-doc-cato fade ${openEditModal ? 'show' : ''}`}
            tabIndex={-1}
            aria-labelledby='exampleModalLabel'
            aria-hidden={!openEditModal}
            role='dialog'
            style={{ display: openEditModal ? 'block' : 'none' }}
        >
            <div className='modal-dialog w-100'>
                <div className='modal-content border-0'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='head-title-edit'>
                            {isEdit ? NEWS_FEED.EDIT : NEWS_FEED.ADD}
                        </span>
                        <button
                            type='button'
                            className='btn-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                            onClick={() => setOpenEditModal(false)}
                        />
                    </div>
                    <div className='modal-body p-0'>
                        <input
                            type='text'
                            name='feeds'
                            value={feed.feeds}
                            className='form-control input-area'
                            ref={inputRef}
                            onChange={handleChange}
                            placeholder={NEWS_FEED.PLACEHOLDER}
                        />
                    </div>
                    {validationError && <p className='text-danger'>{validationError}</p>}

                    <div className='d-flex align-items-center justify-content-end modal-btn'>
                        <button
                            type='button'
                            className='ssc-secondary-white-btn'
                            onClick={() => setOpenEditModal(false)}
                        >
                            Cancel
                        </button>
                        <button
                            type='button'
                            className='ssc-primary-green-btn py-0 w-auto'
                            onClick={handleClick}
                            disabled={isDisabled}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

NewsFeedAddEdit.propTypes = {
    openEditModal: PropTypes.bool,
    setOpenEditModal: PropTypes.func,
    data: PropTypes.object,
    isEdit: PropTypes.bool,
    debouncedApiCall: PropTypes.func,
    accountId: PropTypes.string,
    order: PropTypes.number,
};
