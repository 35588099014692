import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import { NEWS_FEED } from '../../../constants';
import { AdminPageHeader } from '../Common/AdminPagesHeader';
import { getAllNewsFeed, getMaxOrderValue, popup, updateNewsFeedOrder } from '../../../utils';
import { NewsFeedOperation } from './NewsFeedOperation';
import { NewsFeedAddEdit } from '../../shared';

export const NewsFeed = () => {
    const location = useLocation();
    const { state } = location;
    const [accountDetails] = useState(state?.accountUserDetails);
    const dispatch = useDispatch();
    const [response, setResponse] = useState({ data: [] });
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [order, setOrder] = useState(1);

    const debouncedApiCall = useDebounce(() => {
        getAllNewsFeed({ account_id: accountDetails.id })
            .then((res) => {
                dispatch(setLoaderVisibility(false));
                if (res?.data) {
                    setResponse({ data: res.data, count: res.count });
                    setOrder(getMaxOrderValue(res.data));
                }
            })
            .catch((error) => {
                dispatch(setLoaderVisibility(false));
                popup('error', error.message);
            });
    }, 500);

    const onDragEnd = async (e) => {
        if (!e.destination) return;
        if(e.source.index === e.destination.index) return;
        const sorted = reorder(response.data, e.source.index, e.destination.index);
        setResponse((prevState) => ({ data: sorted, count: prevState.count }));
        const data = sorted.map(({ id, order }) => ({ id, order }));
        updateNewsFeedOrder({ data });
    };
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    useEffect(() => {
        dispatch(setLoaderVisibility(true));
        debouncedApiCall();
    }, []);
    return (
        <>
            <AdminPageHeader heading={NEWS_FEED.TITLE} backbutton />
            <>
                <div className='ssc-newsfeed-box'>
                    <div className='p-3 name-box-set d-flex align-items-center justify-content-between'>
                        <div>
                            <p className='title'>Customer</p>
                            <h6 className='name m-0'>{accountDetails.name}</h6>
                        </div>
                        <div className='newsfeed-top-btn'>
                            <button
                                className='ssc-primary-green-btn w-auto'
                                type='button'
                                data-bs-toggle='modal'
                                data-bs-target='#exampleModal-add'
                                onClick={() => setOpenCreateModal(true)}
                            >
                                Add News Feed Entry
                            </button>
                        </div>
                    </div>
                </div>
                <div className='new-table-responsiveness'>
                    <div className='ssc-table-area w-100'>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId='Table'>
                                {(provided) => (
                                    <table
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        className='w-100'
                                    >
                                        <thead>
                                            <tr>
                                                <th>News Feed</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {response.data.map((item, index) => (
                                                <Draggable
                                                    key={item.id}
                                                    draggableId={item.id}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <tr
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <td>{item.feeds}</td>
                                                            <td className='option-td'>
                                                                <div className='d-flex align-items-center justify-content-start options-set'>
                                                                    <NewsFeedOperation
                                                                        data={item}
                                                                        debouncedApiCall={debouncedApiCall}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </tbody>
                                    </table>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>

                <div className='pt-5 pt-3 empty-set d-flex align-items-center justify-content-center flex-column'>
                    <img src='./img/icons/empty-img-doc.svg' alt='img' width={176} height={138} />
                    <p>There are no news feed for this customer</p>
                    <button
                        className='w-auto ssc-primary-green-btn'
                        type='button'
                        data-bs-toggle='modal'
                        data-bs-target='#exampleModal-add'
                        onClick={() => setOpenCreateModal(true)}
                    >
                        Add News Feed Entry
                    </button>
                </div>

                {openCreateModal && (
                    <NewsFeedAddEdit
                        openEditModal={openCreateModal}
                        setOpenEditModal={setOpenCreateModal}
                        isEdit={false}
                        debouncedApiCall={debouncedApiCall}
                        accountId={accountDetails.id}
                        order={order}
                    />
                )}
            </>
        </>
    );
};
