import React from 'react';
import { Edit } from '../../../assets/svg';
import { ROUTES } from '../../../constants';
import { useNavigate } from 'react-router-dom';
export const CsmProfile = ({ id }) => {
    const navigate = useNavigate();
    const handleEditClick = () => {
        navigate(ROUTES.ADMIN.EDIT_PROFILE, {
            state: {
                userId: id,
            },
        });
    };

    return (
        <div className='d-flex align-items-center justify-content-center options-set'>
            <button
                className={`border-0 p-0 bg-white`}
                onClick={() => handleEditClick()}
                id='action-btn'
            >
                <Edit />
            </button>
        </div>
    );
};
