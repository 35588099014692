import { OVERVIEW } from './strings';

export const REQUEST_STATUS = [
    { name: 'Approved', value: 'approved' },
    { name: 'Pending', value: 'pending' },
    { name: 'Requested', value: 'requested' },
    { name: 'Rejected', value: 'rejected' },
];

export const registrationAndDocumentDropdownOptions = [
    { value: 30, name: OVERVIEW.DAYS_30 },
    { value: 60, name: OVERVIEW.DAYS_60 },
    { value: 90, name: OVERVIEW.DAYS_90 },
    { value: 120, name: OVERVIEW.DAYS_120 },
];
