import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { SCORECARD_REPORT, BUTTONS, ROUTES, ERROR, SYMBOL } from '../../../constants';
import { Arrow, SearchIcon } from '../../../assets/svg';
import {
    handleCustomerDropdownFn,
    handleCustomerSelectFn,
    handleSearchChangeFn,
    getAllCustomerApi
} from './ScoreCardsFunctions';
import { ButtonComponent } from '../../shared';
import { useDebounce } from '../../../hooks';
import { getAllScoreCardDetails, popup } from '../../../utils';

export const AdminScoreCardCreateModal = ({openCreateModal, setOpenCreateModal}) => {

    //declarations

    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [isCustomerOpen, setIsCustomerOpen] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [platforms, setPlatforms] = useState([]);
    const [openPlatformDropdown, setOpenPlatformDropdown] = useState(false);
    const [selectedPlatform, setSelectedPlatform] = useState(null);
    const [selectedGrade, setSelectedGrade] = useState(null);
    const [isScorecardExists, setIsScorecardExists] = useState(false);

    const customerDropdownRef = useRef(null);
    const platformDropdownRef = useRef(null);

    //functions

    const handleCustomerDropdown = handleCustomerDropdownFn(setSearchQuery, setIsCustomerOpen, setCustomers, setOpenPlatformDropdown);
    const handleCustomerSelect = handleCustomerSelectFn(setSearchQuery, setSelectedCustomer, setIsCustomerOpen);
    const handleSearchChange = handleSearchChangeFn(setSearchQuery);
    const getAllCustomerListApi = async() => await getAllCustomerApi({setCustomers, searchQuery});

    const handleCreate = () => {
        if (!isScorecardExists) {
            if (selectedCustomer?.id && selectedCustomer?.name && selectedPlatform?.id && selectedGrade) {
                navigate(ROUTES.ADMIN.SCORE_CARD_REPORT_ADMIN, {
                    state : {
                        accountUserDetails: {
                            id: selectedCustomer.id,
                            name: selectedCustomer.name,
                            platform_id: selectedPlatform.id,
                            grade: selectedGrade
                        }
                    }
                });
            } else {
                const err_message = !selectedCustomer?.id ? ERROR.SELECT_CUSTOMER : !selectedPlatform?.id ? ERROR.SELECT_PLATFORM : !selectedGrade ? ERROR.SELECT_PLATFORM_GRADE : '';
                popup('error', err_message);
            }
        } else {
            popup('error', ERROR.SCORECARD_ALREADY_EXISTS);
        }
    }

    const getSelectedCustomerPlatforms = (selectedCustomer) => {
        const selectedCustomerData = customers.find(customer => selectedCustomer.id === customer.id);
        setPlatforms(selectedCustomerData?.platforms);
    }

    const debouncedCall = useDebounce(getAllCustomerListApi, 1000);

    const handlePlatformSelect = (platform) => {
        setOpenPlatformDropdown(false);
        setSelectedPlatform(platform);
    }

    const handlePlatformDropdown = () => {
        if (selectedCustomer) {
            getSelectedCustomerPlatforms(selectedCustomer);
            setIsCustomerOpen(false);
            setOpenPlatformDropdown(prev => !prev);
        } else {
            popup('error', ERROR.SELECT_CUSTOMER);
        }
    }

    const handleGradeSelect = (grade) => {
        setSelectedGrade(grade)
    }

    const checkIsScorecardExists = async (selectedCustomer, selectedPlatform) => {
        const allScorecards = await getAllScoreCardDetails({
            account_id: selectedCustomer ? selectedCustomer.id : '',
            selectedPlatform: selectedPlatform ? selectedPlatform : {},
            list: true
        });
        
        if (allScorecards.data?.length) {
            return setIsScorecardExists(true);
        } else {
            return setIsScorecardExists(false);
        }
    };

    // initial state

    useEffect(() => {
        isCustomerOpen && debouncedCall();
    }, [isCustomerOpen, searchQuery])

    useEffect(() => {
        if (selectedCustomer && selectedPlatform) {
            checkIsScorecardExists(selectedCustomer, selectedPlatform);
        }
    }, [selectedCustomer, selectedPlatform])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (customerDropdownRef.current && !customerDropdownRef.current.contains(event.target)) {
                setIsCustomerOpen(false);
            }
            if (platformDropdownRef.current && !platformDropdownRef.current.contains(event.target)) {
                setOpenPlatformDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [customerDropdownRef, platformDropdownRef]);
    const handleClear = (setData,e) =>{
        e.stopPropagation();
        setData('')
    }
    return (
        <div
            className={`modal custom-modal-doc-cato fade ${openCreateModal ? 'show' : ''}`}
            tabIndex={-1}
            aria-labelledby='exampleModalLabel'
            aria-hidden={!openCreateModal}
            role='dialog'
            style={{ display: openCreateModal ? 'block' : 'none' }}
        >
            <div className='modal-dialog w-100'>
                <div className='modal-content border-0'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='head-title-edit'>
                            {SCORECARD_REPORT.SCORE_CARD_LISTING_PAGE.HEADER_BUTTON}
                        </span>
                        <ButtonComponent clickHandler={() => setOpenCreateModal(false)} className='btn-close'></ButtonComponent>
                    </div>

                    <React.Fragment>
                        <div className="modal-body p-0 mt-32 mb-32 ssc-scorecard-form">
                            <div className="mb-4 d-flex align-items-start flex-column">
                                <label htmlFor="">{SCORECARD_REPORT.COLUMNS.CUSTOMER.NAME}</label>
                                <div ref={customerDropdownRef} className={`wrapper position-relative${isCustomerOpen ? ' active' : ''}`} id="wrapper">
                                    <div onClick={handleCustomerDropdown} className="select-btn" id="select-btn">
                                        {selectedCustomer ? <span style={{ color: 'var(--col-primary)' }}> {selectedCustomer?.name} </span> : <span>{SCORECARD_REPORT.COLUMNS.CUSTOMER.PLACEHOLDER} </span>}
                                        <div className=''>
                        {selectedCustomer ? (
                            <button
                                type='button'
                                className='btn-close w-25 me-1'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                                onClick={(e)=>handleClear(setSelectedCustomer,e)}
                            />
                        ) : (
                            <Arrow/>
                        )}
                    </div>
                                    </div>
                                    <div className="content position-absolute w-100">
                                        <div className="search position-relative">
                                            <div className='search-icon-svg'>
                                                <SearchIcon width={13} height={13} />
                                            </div>
                                            <input
                                                onChange={handleSearchChange}
                                                type="search"
                                                name=""
                                                placeholder="Search customer"
                                                value={searchQuery || ''}
                                                id=""
                                                className="input-dropdown-set"
                                            />
                                        </div>
                                        <ul className="options mb-0">
                                            {customers?.map(customer => (
                                                <li key={customer.id} onClick={() => handleCustomerSelect(customer)}>{customer.name}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4 d-flex align-items-start flex-column">
                                <label htmlFor="">{SCORECARD_REPORT.COLUMNS.PLATFORM.NAME}</label>
                                <div ref={platformDropdownRef} className={`wrapper position-relative${openPlatformDropdown ? ' active' : ''}`} id="wrapper">
                                    <div onClick={handlePlatformDropdown} className="select-btn" id="select-btn">
                                        {selectedPlatform ? <span style={{ color: 'var(--col-primary)' }}> {selectedPlatform?.name}<sup>{SYMBOL.REGISTERED}</sup></span> : <span>{SCORECARD_REPORT.COLUMNS.PLATFORM.PLACEHOLDER} </span>}
                                        <div className=''> 
                                            {selectedPlatform ? 
                                            <button
                                                type='button'
                                                className='btn-close w-25 me-1'
                                                data-bs-dismiss='modal'
                                                aria-label='Close'
                                                onClick={(e)=>handleClear(setSelectedPlatform,e)}
                                            /> : <div><Arrow /></div>}
                                        </div>
                                    </div>
                                    <div className="content position-absolute w-100">
                                        <ul className="options mb-0 mt-0">
                                            {
                                                platforms.length ? 
                                                    (platforms?.map(platform => (
                                                        <li key={platform.id} onClick={() => handlePlatformSelect(platform)}>{platform.name}<sup>{SYMBOL.REGISTERED}</sup></li>
                                                    ))) : 
                                                    <li>{ERROR.NO_PLATFORM}</li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4 d-flex align-items-start flex-column">
                                <label htmlFor="">{SCORECARD_REPORT.COLUMNS.PLATFORM_GRADE.NAME}</label>
                                <div className='d-flex align-items-center justify-content-center gap-5 mb-0 mt-2'>
                                    <button className={`ssc-secondary-white-btn btn-grey-border ${selectedGrade === 'red' ? 'grade-btn-active' : ''}`} onClick={() => handleGradeSelect('red') }>
                                        <div className='dot-circle red me-2'></div>
                                        <div className="label-color font-16px"><span>Red</span></div>
                                    </button>
                                    <button className={`ssc-secondary-white-btn btn-grey-border ${selectedGrade === 'yellow' ? 'grade-btn-active' : ''}`} onClick={() => handleGradeSelect('yellow')}>
                                        <div className='dot-circle yellow me-2'></div>
                                        <div className="label-color font-16px"><span>Yellow</span></div>
                                    </button>
                                    <button className={`ssc-secondary-white-btn btn-grey-border ${selectedGrade === 'green' ? 'grade-btn-active' : ''}`} onClick={() => handleGradeSelect('green')}>
                                        <div className='dot-circle green me-2'></div>
                                        <div className="label-color font-16px"><span>Green</span></div>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex align-items-center justify-content-end modal-btn'>
                            <ButtonComponent clickHandler={() => setOpenCreateModal(false)} className='ssc-secondary-white-btn'>
                                {BUTTONS.CANCEL_BUTTON}
                            </ButtonComponent>

                            <ButtonComponent clickHandler={handleCreate} disabled={!selectedCustomer} className='ssc-primary-green-btn py-0 w-auto'>
                                {BUTTONS.CREATE_SCORECARD}
                            </ButtonComponent>

                        </div>
                    </React.Fragment>
                </div>
            </div>
        </div>
    );
};

AdminScoreCardCreateModal.propTypes = {
    openCreateModal: PropTypes.bool,
    setOpenCreateModal: PropTypes.func,
};
