import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AdminPageHeader } from '../../Admin/Common/AdminPagesHeader';
import { setLoaderVisibility } from '../../../redux';
import { HIRING_CLIENT, HIRING_CLIENT_COLUMNS, LOCAL_STORAGE } from '../../../constants';
import { getHiringClients, getLocalStorageItem, popup } from '../../../utils';
import { usePagination, useDebounce } from '../../../hooks';
import { Table } from '../../shared';

export const HiringClientListing = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { state } = location;
    const [response, setResponse] = useState({ data: [], count: 0 });
    const [search, setSearch] = useState('');
    const [redirected, setRedirected] = useState(state?.id ? true : false)
    const pagination = usePagination(response.data.length, response.count);
    const accountId = getLocalStorageItem(LOCAL_STORAGE.CUSTOMER_ID);

    const debouncedApiCall = useDebounce(() => {
        getHiringClients({ account_id: accountId, offset: pagination.offset, limit: pagination.limit, search })
            .then((res) => {
                if (res?.data) {
                    if (redirected) {
                        const hc = res.data.filter(hc => hc.id === state.id);
                        setResponse({ data: hc, count: hc.length })
                    } else setResponse({ data: res.data, count: res.count });
                }
                dispatch(setLoaderVisibility(false));
            })
            .catch((error) => {
                popup('error', error.message);
                dispatch(setLoaderVisibility(false));
            });
    }, 500);

    useEffect(() => {
        if (accountId) {
            !search && dispatch(setLoaderVisibility(true));
            debouncedApiCall();
        }
    }, [pagination.limit, pagination.offset, search, accountId]);

    useEffect(() => {
        setRedirected(false);
    }, [search]);

    return (
        <>
            <AdminPageHeader
                heading={HIRING_CLIENT.PAGE_HEADING}
                buttonText={HIRING_CLIENT.BUTTON_TEXT}
                searchBarPlaceholder={HIRING_CLIENT.SEARCH_BAR_PLACEHOLDER}
                setSearch={setSearch}
                count={pagination.dataCount}
                setCurrentPage={pagination.setCurrentPage}
                backbutton
            />
            <Table
                columns={HIRING_CLIENT_COLUMNS}
                data={response.data}
                pagination={pagination}
                pageName={HIRING_CLIENT.PAGE_HEADING}
            />
        </>
    );
};
