/* eslint-disable no-constant-condition */
import PropTypes from 'prop-types';
import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { BackArrow, GreenPlus, SearchIcon } from '../../../assets/svg';
import { ButtonComponent, CustomDropdown } from '../../shared';
import ScoreCardAdminHeader from '../ScoreCardReportAdmin/ScoreCardAdminHeader';
import { BUTTONS } from '../../../constants';

export const AdminPageHeader = ({
    debouncedApiCall,
    setSelectedTpa,
    backbutton,
    heading,
    buttonText,
    searchBarPlaceholder,
    setSearch,
    showAddButton = false,
    count = 0,
    handleAddClick,
    AddModal,
    adminScoreCard = false,
    accountUserDetails = {},
    setCurrentPage,
    showGradeButton,
    filterConfig = [],
    showSearchBar = true
}) => {
    const [showBar, setShowBar] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [openGradeSelectModal, setOpenGradeSelectModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setShowBar(searchValue.trim() !== '' || count > 0);
    }, [searchValue, count]);

    const handleSearchChange = (event) => {
        const searchQuery = event.target.value;
        setSearchValue && setSearchValue(searchQuery.trim());
        setCurrentPage && setCurrentPage(1);
        setSearch && setSearch(searchQuery.trim());
    };
    const handleBackButton = () => {
        navigate(-1);
    };

    const handleGradeButtonClick = () => {
        setOpenGradeSelectModal(true);
    };

    return (
        <>
            {/* page header  */}
            <section
                id='AdminPageHeader01'
                className='ssc-header-title d-flex align-items-center justify-content-between mb-4'
            >
                <div className='d-flex align-items-center'>
                    {backbutton && (
                        <ButtonComponent
                            className='d-flex align-items-center border-0 bg-white'
                            type='button'
                            clickHandler={handleBackButton}
                        >
                            <BackArrow />
                        </ButtonComponent>
                    )}
                    <h1 className='title-btn mb-3 d-flex align-items-center'>
                        <span style={{ marginLeft: '10px' }}> {heading}</span>
                    </h1>
                </div>
                <div className='d-flex'>
                    {showGradeButton && (
                        <button
                            className='ssc-secondary-white-btn justify-content-end me-4'
                            type='button'
                            data-bs-toggle='modal'
                            data-bs-target='#exampleModal-add'
                            onClick={handleGradeButtonClick}
                        >
                            <p>{BUTTONS.UPDATE_PLATFORM_GRADE}</p>
                        </button>
                    )}
                    {showAddButton && (
                        <>
                            <button
                                className='ssc-secondary-white-btn'
                                type='button'
                                data-bs-toggle='modal'
                                data-bs-target='#exampleModal-add'
                                onClick={handleAddClick}
                            >
                                <GreenPlus />
                                <p>{buttonText}</p>
                            </button>
                        </>
                    )}
                </div>
            </section>

            {/* search bar */}
            {adminScoreCard ? (
                <div className='py-2 mb-3'>
                    <ScoreCardAdminHeader
                        accountUserDetails={accountUserDetails}
                        searchFn={handleSearchChange}
                        setSelectedTpa={setSelectedTpa}
                        setSearchQuery={setSearchValue}
                        searchValue={searchValue}
                        debouncedApiCall={debouncedApiCall}
                        isOpenGradeSelectModal={openGradeSelectModal}
                        setOpenGradeSelectModal={setOpenGradeSelectModal}
                    />
                </div>
            ) : (
                showSearchBar && (
                    <section id='AdminPageHeader02' className='ssc-search-box mb-4 row mx-0'>
                        <div className='col-6 px-0'>
                            {showBar && (
                                <div className='search-box d-flex align-items-center justify-content-start'>
                                    <button className='bg-white p-0 border-0 d-flex align-items-center'>
                                        <SearchIcon />
                                    </button>
                                    <input
                                        type='text'
                                        maxLength={40}
                                        className='search-area border-0 w-100 py-1'
                                        placeholder={searchBarPlaceholder}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                            )}
                        </div>
                        {/* filter section */}
                        {filterConfig.length > 0 && (
                            <div className='d-flex justify-content-end align-items-center col-6'>
                                {filterConfig.map((filter, index) => (
                                    <CustomDropdown
                                        key={index} // Using index as key if filter.name is not unique. Prefer unique identifiers if available.
                                        options={filter.options}
                                        isDropdownOpen={filter.isFilterOpen}
                                        setIsDropdownOpen={filter.setIsFilterOpen}
                                        selectedOption={filter.selectedFilterName}
                                        handleOptionSelect={(option) => {
                                            filter.handleFunction(option.value, option.name);
                                            filter.setIsFilterOpen(false);
                                        }}
                                    />
                                ))}
                            </div>
                        )}
                    </section>
                )
            )}
            {AddModal}
        </>
    );
};

AdminPageHeader.propTypes = {
    debouncedApiCall: PropTypes.func,
    setSelectedTpa: PropTypes.func,
    heading: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    searchBarPlaceholder: PropTypes.string,
    setSearch: PropTypes.func,
    count: PropTypes.number,
    handleAddClick: PropTypes.func,
    accountUserDetails: PropTypes.object,
    adminScoreCard: PropTypes.bool,
    showAddButton: PropTypes.bool,
    AddModal: PropTypes.node,
    backbutton: PropTypes.bool,
    setCurrentPage: PropTypes.func,
    showGradeButton: PropTypes.bool,
    filterConfig: PropTypes.array,
    showSearchBar: PropTypes.bool
};
