/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */

import { capitalize } from 'lodash';
import { Broadcast } from '../assets/svg';
import {
    DocumentCategoryOperation,
    ScoreCardOperation,
    AdminScoreCardListingOperation,
    RegulatoryIssueOperation,
    CsmProfile,
    DocumentRequestOperation,
} from '../components/Admin';
import {
    ImageWithFallback,
    GoButton,
    ButtonComponent,
    TooltipComponent,
    EditButton,
} from '../components/shared';
import { broadcastRegulatoryIssues, popup, getLocalStorageItem } from '../utils';
import { exportStore } from '..';
import { setLoaderVisibility } from '../redux';
import { REGULATORY_ISSUE, ROLES, LOCAL_STORAGE, SYMBOL } from './strings';
import { ROUTES } from './routes';
import { TOOLTIP_MESSAGES } from './messages';
import { formatPhoneToUSFormat } from '../utils/common/formatPhoneNumber';
import DigitalSafetyLibraryActions from '../components/shared/common/DigitalSafetyLibraryActions';
import moment from 'moment';

const userRole = getLocalStorageItem(LOCAL_STORAGE.ROLE_CODE);
export const CSM_COLUMNS = [
    {
        name: 'CSM Name',
        classNameTH: 'csm-name',
        classNameTD: 'csm-img-td',
        sort: true,
        dataField: ['full_name', 'image'],
        customFormatter: (data) => {
            return (
                <div className='d-flex align-items-center img-set'>
                    <div className='img-area'>
                        <ImageWithFallback
                            src={data.image}
                            alt={'img'}
                            width='24'
                            height='24'
                            className='img-tag'
                        />
                    </div>
                    <span>{data.full_name}</span>
                </div>
            );
        },
    },
    {
        name: 'Email ID',
        classNameTH: 'csm-phone',
        classNameTD: 'email-td',
        sort: true,
        dataField: ['email'],
    },
    {
        name: 'Phone Number',
        classNameTH: 'csm-id',
        classNameTD: '',
        dataField: ['phone'],
        customFormatter: (data) => {
            return data?.phone ? formatPhoneToUSFormat(data.phone) : '-';
        },
    },
    {
        name: 'Action',
        classNameTH: 'option-td',
        classNameTD: 'option-td',
        dataField: ['id'],
        customFormatter: ({ id }) => {
            return <CsmProfile id={id} />;
        },
    },
];

export const CUSTOMER_COLUMNS = [
    {
        name: 'Customer Name',
        sort: true,
        classNameTH: '',
        classNameTD: '',
        dataField: ['name'],
    },
    {
        name: 'Email ID',
        classNameTH: '',
        classNameTD: 'email-td',
        sort: true,
        dataField: ['email'],
    },
    {
        name: 'Phone Number',
        classNameTH: '',
        classNameTD: '',
        dataField: ['phone'],
        customFormatter: (data) => {
            return data?.phone ? formatPhoneToUSFormat(data.phone) : '-';
        },
    },
    {
        name: 'CSM',
        classNameTH: '',
        classNameTD: '',
        sort: true,
        dataField: ['csm_name'],
    },
    {
        name: 'Platforms',
        classNameTH: 'tab-td',
        classNameTD: 'tab-td',
        dataField: ['platforms'],
        customFormatter: (platformData) => {
            return (
                <div className='d-flex align-items-center justify-content-start tab-set flex-wrap'>
                    {platformData?.platforms?.some(
                        (item) => item.id !== null && item.name !== null,
                    ) ? (
                        platformData.platforms.map(
                            (item) =>
                                item.id !== null &&
                                item.name !== null && (
                                    <span className='platform-boundary' key={item.id}>
                                        {item.name}
                                        <sup>{SYMBOL.REGISTERED}</sup>
                                    </span>
                                ),
                        )
                    ) : (
                        <span>None</span>
                    )}
                </div>
            );
        },
    },
    {
        name: 'Created By',
        classNameTH: '',
        classNameTD: '',
        dataField: ['source'],
    },
    {
        name: 'Action',
        classNameTH: 'option-td',
        classNameTD: 'option-td',
        dataField: ['id'],
        customFormatter: ({ id }) => {
            return (
                <div className='d-flex align-items-center justify-content-center options-set'>
                    <EditButton
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        navigateTo={ROUTES.ADMIN.CUSTOMER_EDIT}
                        id={id}
                    ></EditButton>
                </div>
            );
        },
    },
];

export const HIRING_CLIENT_COLUMNS = [
    {
        name: 'Hiring Client',
        classNameTH: 'hire-client',
        classNameTD: '',
        sort: true,
        dataField: ['name'],
    },
    {
        name: 'Platforms',
        classNameTH: 'tpa-area',
        classNameTD: 'table-pill w-auto',
        dataField: ['platform_name', 'platform_id'],
        customFormatter: (data) => {
            return (
                <div className='d-flex align-items-center justify-content-start tab-set flex-wrap'>
                    <span key={data.platform_id}>
                        {data.platform_name}
                        <sup>{SYMBOL.REGISTERED}</sup>
                    </span>
                </div>
            );
        },
    },
    {
        name: 'Grade',
        classNameTH: '',
        classNameTD: '',
        dataField: ['hiring_client_grade'],
        customFormatter: (data) => {
            return <span key={data.hiring_client_grade}>{data.hiring_client_grade || '-'}</span>;
        },
    },
    {
        name: 'Action',
        classNameTH: 'option-td-go-btn',
        classNameTD: 'option-td',
        dataField: ['id'],
        customFormatter: ({ id }) => {
            return <GoButton hiringClientId={id} navigateTo={ROUTES.CUSTOMER.DOCUMENTS} />;
        },
    },
];

export const DOCUMENT_CATEGORY_COLUMNS = [
    {
        name: 'Documents Categories',
        classNameTH: '',
        classNameTD: '',
        sort: true,
        dataField: ['name'],
    },
    {
        name: 'Action',
        classNameTH: 'option-td',
        classNameTD: 'option-td',
        dataField: ['id', 'name'],
        customFormatter: (data, debouncedApiCall) => {
            return <DocumentCategoryOperation data={data} debouncedApiCall={debouncedApiCall} />;
        },
    },
];
export const ADMIN_COLUMNS = [
    {
        name: 'Admin Name',
        classNameTH: '',
        classNameTD: '',
        sort: true,
        dataField: ['full_name'],
    },
    {
        name: 'Email ID',
        classNameTH: '',
        classNameTD: 'email-td',
        sort: true,
        dataField: ['email'],
    },
    {
        name: 'Phone Number',
        classNameTH: '',
        classNameTD: '',
        dataField: ['phone'],
        customFormatter: (data) => {
            return data?.phone ? formatPhoneToUSFormat(data.phone) : '-';
        },
    },
];
export const DOCUMENT_COLUMNS = [
    {
        name: 'Documents',
        classNameTH: '',
        classNameTD: '',
        sort: true,
        dataField: ['document_category_name'],
    },
    {
        name: 'Hiring Client',
        classNameTH: '',
        classNameTD: '',
        sort: true,
        dataField: ['hiring_client_name'],
    },
    {
        name: 'Platform',
        classNameTH: '',
        classNameTD: '',
        sort: true,
        dataField: ['platform_name', 'id'],
        customFormatter: (data) => {
            return data.platform_name ? (
                <div key={data.id}>
                    {data.platform_name}
                    <sup>{SYMBOL.REGISTERED}</sup>
                </div>
            ) : (
                '-'
            );
        },
    },
    {
        name: 'CSM',
        classNameTH: '',
        classNameTD: '',
        sort: true,
        dataField: ['csm_name'],
        hideColumn: userRole === ROLES.CSM,
    },
    {
        name: 'Customer',
        classNameTH: '',
        classNameTD: '',
        sort: true,
        dataField: ['account_name'],
        customFormatter: (data) => {
            return <div key={data.id}>{data.account_name}</div>;
        },
    },
    {
        name: 'Priority',
        classNameTH: '',
        classNameTD: 'priority-set',
        dataField: ['priority'],
        customFormatter: (data) => {
            return (
                <div className={`priority ${data.priority}-priority`}>
                    {capitalize(data.priority)}
                </div>
            );
        },
    },
    {
        name: 'Status',
        classNameTH: '',
        classNameTD: '',
        dataField: ['status'],
        customFormatter: ({ status }) => {
            return (
                <div className='status-set'>
                    <div className={`status ${status}-status`}>
                        {status.charAt(0).toUpperCase() + status.slice(1)}
                    </div>
                </div>
            );
        },
    },
    {
        name: 'Action',
        classNameTH: 'option-td',
        classNameTD: 'option-td',
        dataField: ['id'],
        customFormatter: (data, debouncedApiCall) => {
            return <DocumentRequestOperation data={data} debouncedApiCall={debouncedApiCall} />;
        },
    },
];

export const SCORE_CARD_CUSTOMER_COLUMNS = [
    {
        name: 'Hiring Client',
        classNameTH: '',
        classNameTD: '',
        sort: true,
        dataField: ['hiring_client_name'],
    },
    {
        name: 'Platform',
        classNameTH: '',
        classNameTD: '',
        sort: true,
        dataField: ['platform_name', 'platform_id'],
        customFormatter: (data) => {
            return (
                <div className='d-flex align-items-center justify-content-start tab-set flex-wrap'>
                    <span className='platform-boundary' key={data.platform_id}>
                        {data.platform_name}
                        <sup>{SYMBOL.REGISTERED}</sup>
                    </span>
                </div>
            );
        },
    },
    {
        name: 'Grade',
        classNameTH: '',
        classNameTD: '',
        dataField: ['hc_grade'],
    },
    {
        name: 'Note',
        classNameTH: '',
        classNameTD: 'dot-sentence',
        dataField: ['notes'],
    },
    {
        name: 'Action',
        classNameTH: 'option-td',
        classNameTD: 'option-td',
        dataField: ['hiring_client_id'],
        customFormatter: ({ hiring_client_id }) => {
            return (
                <GoButton
                    hiringClientId={hiring_client_id}
                    navigateTo={ROUTES.CUSTOMER.DOCUMENTS}
                />
            );
        },
    },
];

export const CUSTOMER_TABVIEW_COLUMNS = [
    {
        name: 'Customer Name',
        classNameTH: '',
        classNameTD: '',
        dataField: 'name',
    },
    {
        name: 'Primary User',
        classNameTH: '',
        classNameTD: '',
        dataField: 'users',
        customFormatter: ({ data }) => {
            return (
                <div className='d-flex align-items-center justify-content-start options-set'>
                    {data.primary_user_full_name ? data.primary_user_full_name : '-'}
                </div>
            );
        },
    },
    {
        name: 'Phone',
        classNameTH: '',
        classNameTD: '',
        dataField: 'phone',
        customFormatter: (data) => {
            return data?.phone ? formatPhoneToUSFormat(data.phone) : '-';
        },
    },
    {
        name: 'Email Address',
        classNameTH: '',
        classNameTD: 'email-td',
        dataField: 'email',
    },
    {
        name: 'CSM',
        classNameTH: '',
        classNameTD: 'email-td',
        dataField: ['csm_user_name'],
        customFormatter: ({ full_name, image }) => {
            return (
                <div key={full_name}>
                    <img src={image} alt='user-img' width='24' height='24' />
                    {full_name}
                </div>
            );
        },
    },
    {
        name: 'Hiring Client',
        classNameTH: '',
        classNameTD: 'email-td',
        dataField: ['hiring_clients'],
        customFormatter: (data) => {
            return <div key={data.id}>{data.name}</div>;
        },
    },
    {
        name: 'Platforms',
        classNameTH: '',
        classNameTD: 'email-td',
        dataField: ['tpas'],
        customFormatter: (data) => {
            return (
                <div key={data.id}>
                    {data.name}
                    <sup>{SYMBOL.REGISTERED}</sup>
                </div>
            );
        },
    },
];

export const SCORE_CARD_COLUMNS_ADMIN = [
    {
        name: 'Hiring Client',
        classNameTH: '',
        classNameTD: '',
        sort: true,
        dataField: ['hiring_client_name'],
    },
    {
        name: 'Platform',
        classNameTH: '',
        classNameTD: 'tab-pill-scorecard',
        dataField: ['platform_name', 'platform_id'],
        sort: true,
        customFormatter: (data) => {
            return (
                <div className='d-flex align-items-center justify-content-start tab-set flex-wrap'>
                    <span key={data.platform_id}>
                        {data.platform_name}
                        <sup>{SYMBOL.REGISTERED}</sup>
                    </span>
                </div>
            );
        },
    },
    {
        name: 'Grade',
        classNameTH: '',
        classNameTD: '',
        dataField: ['hc_grade'],
    },
    {
        name: 'Notes',
        classNameTH: '',
        classNameTD: 'dot-sentence',
        dataField: ['notes'],
    },
    {
        name: 'Action',
        classNameTH: 'option-td',
        classNameTD: 'option-td',
        dataField: [
            'id',
            'hc_grade',
            'hiring_client_name',
            'hiring_client_id',
            'platform_id',
            'status',
            'notes',
            'pending',
            'created_by',
            'account_id',
        ],
        customFormatter: (item, debouncedApiCall) => {
            return <ScoreCardOperation data={item} debouncedApiCall={debouncedApiCall} />;
        },
    },
];

export const DOC_CUSTOMER_COLUMNS = [
    {
        name: 'Documents',
        classNameTH: 'me-1',
        classNameTD: 'table-pill w-auto',
        sort: true,
        dataField: ['document_category_name'],
        customFormatter: (data) => {
            return <span className={`document-fix-width`}>{data.document_category_name}</span>;
        },
    },
    {
        name: 'Hiring Client',
        classNameTH: '',
        classNameTD: '',
        sort: true,
        dataField: ['hiring_client_name'],
    },
    {
        name: 'Platform',
        classNameTH: '',
        classNameTD: '',
        sort: true,
        dataField: ['platform_name'],
        customFormatter: (data) => {
            return data.platform_name ? (
                <div key={data.id}>
                    {data.platform_name}
                    <sup>{SYMBOL.REGISTERED}</sup>
                </div>
            ) : (
                '-'
            );
        },
    },
    {
        name: 'Priority',
        classNameTH: '',
        classNameTD: 'priority-set',
        dataField: ['priority'],
        customFormatter: (data) => {
            return (
                <div className={`priority ${data.priority}-priority`}>
                    {capitalize(data.priority)}
                </div>
            );
        },
    },
    {
        name: 'Status',
        classNameTH: '',
        classNameTD: 'option-td',
        dataField: ['status'],
        customFormatter: (data) => {
            return (
                <div className='status-set'>
                    <div className={`status ${data.status}-status`}>{capitalize(data.status)}</div>
                </div>
            );
        },
    },
];
export const ADMIN_SCORE_CARD_LISTING_COLUMNS = [
    {
        name: 'Grade',
        classNameTH: '',
        classNameTD: '',
        dataField: ['grade'],
        customFormatter: (data) => {
            const color = data.grade;
            return (
                <div className='dot-set dot-set-width-40'>
                    {data.grade ? (
                        <div className={`col-dot ${color.toLowerCase()}-dot`} />
                    ) : (
                        <TooltipComponent tooltipText={TOOLTIP_MESSAGES.NO_GRADE_SELECTED}>
                            <div className={`col-dot empty-dot`} />
                        </TooltipComponent>
                    )}
                </div>
            );
        },
    },
    {
        name: 'Customer',
        classNameTH: '',
        classNameTD: '',
        sort: true,
        dataField: ['account_name'],
    },
    {
        name: 'CSM',
        classNameTH: '',
        classNameTD: '',
        sort: true,
        dataField: ['csm_name'],
    },
    {
        name: 'Platform',
        classNameTH: '',
        classNameTD: 'tab-pill-scorecard',
        sort: true,
        dataField: ['platform_name', 'platform_id'],
        customFormatter: (data) => {
            return (
                <div className='d-flex align-items-center justify-content-start tab-set flex-wrap'>
                    <span className='platform-boundary' key={data.platform_id}>
                        {data.platform_name}
                        <sup>{SYMBOL.REGISTERED}</sup>
                    </span>
                </div>
            );
        },
    },
    {
        name: 'Action',
        classNameTH: 'option-td',
        classNameTD: 'option-td',
        dataField: ['account_id', 'account_name', 'platform_id'],
        customFormatter: (data, debouncedApiCall) => {
            return (
                <AdminScoreCardListingOperation data={data} debouncedApiCall={debouncedApiCall} />
            );
        },
    },
];

export const REGULATORY_ISSUE_COLUMNS = [
    {
        name: 'Regulatory Issue',
        classNameTH: '',
        classNameTD: '',
        dataField: ['content', 'title'],
        customFormatter: (data, debouncedApiCall) => {
            return (
                <span>
                    <div>
                        <b>{data.title}</b>
                    </div>
                    <div>{data.content}</div>
                </span>
            );
        },
    },
    {
        name: 'Broadcast',
        classNameTH: 'text-center',
        classNameTD: 'd-flex justify-content-center',
        dataField: ['id', 'broadcasted_at'],
        customFormatter: (data, debouncedApiCall) => {
            return (
                <div className='text-center hov-effect-boardcast'>
                    <ButtonComponent
                        className='border-0 p-0 background-color-transparent'
                        disabled={data.broadcasted_at !== null}
                        clickHandler={async () => {
                            try {
                                exportStore.dispatch(setLoaderVisibility(true));
                                await broadcastRegulatoryIssues({ id: data.id });
                                debouncedApiCall();
                                popup('success', REGULATORY_ISSUE.BROADCASTED);
                            } catch (error) {
                                exportStore.dispatch(setLoaderVisibility(false));
                                popup('error', error.message);
                            }
                        }}
                    >
                        <Broadcast color={data.broadcasted_at !== null ? '#4b5564' : '#17B1A4'} />
                    </ButtonComponent>
                </div>
            );
        },
    },
    {
        name: 'Action',
        classNameTH: 'option-td',
        classNameTD: '',
        dataField: ['id', 'title', 'content'],
        customFormatter: (data, debouncedApiCall) => {
            return <RegulatoryIssueOperation data={data} debouncedApiCall={debouncedApiCall} />;
        },
    },
];

export const DIGITAL_SAFETY_LIBRARY_COLUMNS = [
    {
        name: 'File',
        classNameTH: '',
        classNameTD: '',
        dataField: ['file_name'],
        sort: true,
        customFormatter: (data) => {
            return <span>{data.file_name}</span>;
        },
    },
    {
        name: 'Customer Name',
        classNameTH: '',
        classNameTD: '',
        dataField: ['assigned_to'],
        sort: true,
        customFormatter: (data) => {
            return <span>{data.assigned_to}</span>;
        },
    },
    {
        name: 'Primary User',
        classNameTH: '',
        classNameTD: '',
        dataField: ['primary_user_name'],
        sort: true,
        customFormatter: (data) => {
            return <span>{data.primary_user_name}</span>;
        },
    },
    {
        name: 'Uploaded by',
        classNameTH: '',
        classNameTD: '',
        dataField: ['uploaded_by'],
        sort: true,
        customFormatter: (data) => {
            return <span>{data.uploaded_by}</span>;
        },
    },
    {
        name: 'Uploaded At',
        classNameTH: '',
        classNameTD: '',
        sort: true,
        dataField: ['created_at'],
        customFormatter: (data) => {
            return <span>{moment(data.created_at).format('HH:mm, Do MMMM YYYY')}</span>;
        },
    },
    {
        name: 'File Type',
        classNameTH: '',
        classNameTD: '',
        sort: true,
        dataField: ['s3_key'],
        customFormatter: (data) => {
            return <span>{data.s3_key.split('.').pop()}</span>;
        },
    },
    {
        name: 'Action',
        classNameTH: 'option-td',
        classNameTD: '',
        dataField: ['id', 'file_name', 's3_key'],
        customFormatter: (data, debouncedApiCall) => {
            return <DigitalSafetyLibraryActions data={data} debouncedApiCall={debouncedApiCall} />;
        },
    },
];

export const DIGITAL_SAFETY_LIBRARY_CUSTOMER_COLUMNS = [
    {
        name: 'File',
        classNameTH: '',
        classNameTD: '',
        dataField: ['file_name'],
        sort: true,
        customFormatter: (data) => {
            return <span>{data.file_name}</span>;
        },
    },

    {
        name: 'Uploaded At',
        classNameTH: '',
        classNameTD: '',
        sort: true,
        dataField: ['created_at'],
        customFormatter: (data) => {
            return <span>{moment(data.created_at).format('HH:mm, Do MMMM YYYY')}</span>;
        },
    },
    {
        name: 'Action',
        classNameTH: 'option-td',
        classNameTD: '',
        dataField: ['id', 'file_name', 's3_key'],
        customFormatter: (data, debouncedApiCall) => {
            return <DigitalSafetyLibraryActions data={data} debouncedApiCall={debouncedApiCall} />;
        },
    },
];

export const SCORECARD_EMAIL_LOGS_COLUMNS = [
    {
        name: 'Delivery Status',
        classNameTH: '',
        classNameTD: '',
        dataField: ['status'],
        sort: true,
        customFormatter: (data) => {
            const color = data.status === 'failure';
            return (
                <div className={`dot-set dot-set-width-40 ${color && 'text-danger'}`}>
                    {capitalize(data.status)}
                </div>
            );
        },
    },
    {
        name: 'Time',
        classNameTH: '',
        classNameTD: '',
        sort: true,
        dataField: ['created_at'],
        customFormatter: (data) => (
            <span>{moment(data.created_at).format('HH:mm, Do MMMM YYYY')}</span>
        ),
    },
    {
        name: 'Sent By',
        classNameTH: '',
        classNameTD: '',
        sort: true,
        dataField: ['created_by'],
    },
];
