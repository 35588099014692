/*
 constants related to API endpoints, such as base URLs, 
 route paths, API error codes, request methods, or response statuses.
*/

// methods
export const METHODS = {
    UPDATE: 'UPDATE',
    POST: 'POST',
    GET: 'GET',
    DELETE: 'DELETE',
    PUT: 'PUT',
    PATCH: 'PATCH',
};

// headers
export const HEADERS = {
    APPLICATION_JSON: 'application/json',
    AUTHORIZATION: 'Bearer',
    X_FRONTEND_URL: 'x-frontend-url',
    X_RESET_PASSWORD_ROUTE: 'x-reset-password-route',
};

// endpoints
export const API = {
    ACCOUNT: '/account',
    ACCOUNT_HC_PLATFORM: '/account-hiring-client-platform',
    ACCOUNT_USER: '/account_user',
    ADMIN: '/admin',
    ALL: '/all',
    CHANGE_STATUS: '/change-active-status',
    CUSTOMER_ENDPOINT: '/customer',
    CUSTOMER_FEEDBACK: '/customer-feedback',
    CUSTOMER_LOGIN: '/user/login',
    DIGITAL_DELIVERY: '/digital-delivery',
    DOCUMENT_CATEGORY: '/document-category',
    DOCUMENT_REQUEST: '/document-request',
    DOCUMENT_UPLOAD: '/document-upload',
    FETCH_CODE: '/fetchCode',
    GRADE: '/grade',
    HIRING_CLIENTS: '/hiring-client',
    IMPERSONATE: '/impersonate',
    INTERNAL_USER: '/user',
    LOGIN: '/login',
    NEWS_FEED: '/news-feed',
    NOTIFICATION: '/notification',
    ORDER: '/order',
    OVERVIEW: '/overview',
    PLATFORM: '/platform',
    REFRESH_TOKEN: '/refresh-token',
    REGULATORY: '/regulatory',
    REPORT: '/report',
    RESET_INITIATE: '/reset-initiate',
    RESET_PASSWORD: '/reset-password',
    SCORECARD: '/scorecard',
    SCORECARD_EMAIL_LOG: '/scorecard-email-log',
    SIGNED_DD_URL: '/digital-delivery/signed-url',
    SIGNED_URL: '/signed-url',
    USER: '/user',
    VALIDATE_RESET_LINK: '/validate-reset-link',
};

export const HTTP_CODES = {
    INVALID_TOKEN: 498,
    INTERNAL_SERVER_ERROR: 500,
};
