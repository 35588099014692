import React from 'react';
import PropTypes from 'prop-types';

const FeedbackCross = ({ className = '' }) => (
    <svg
        className={className}
        width='24'
        height='25'
        viewBox='0 0 24 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <rect y='0.875' width='23.2849' height='23.2849' rx='11.6424' fill='white' />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M15.6982 9.87605C16.0888 9.48553 16.0888 8.85236 15.6982 8.46184C15.3077 8.07131 14.6745 8.07131 14.284 8.46184L11.6423 11.1036L9.00056 8.46184C8.61004 8.07131 7.97687 8.07131 7.58635 8.46184C7.19583 8.85236 7.19583 9.48553 7.58635 9.87605L10.2281 12.5178L7.58635 15.1595C7.19583 15.55 7.19583 16.1832 7.58635 16.5737C7.97687 16.9643 8.61004 16.9643 9.00056 16.5737L11.6423 13.932L14.284 16.5737C14.6745 16.9643 15.3077 16.9643 15.6982 16.5737C16.0888 16.1832 16.0888 15.55 15.6982 15.1595L13.0565 12.5178L15.6982 9.87605Z'
            fill='#738DA6'
        />
    </svg>
);
FeedbackCross.propTypes = {
    className: PropTypes.string,
}


export default FeedbackCross;
