import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import NameBox from './NameBox';
import { ViewAll, DocumentCategoryAddEdit, AddNewCustomer } from '../../shared';
import CardHead from './CardHead';
import { DASHBOARD_COLUMNS, ROLES, ROUTES, LOCAL_STORAGE } from '../../../constants';
import { getLocalStorageItem } from '../../../utils';

export default function Card({ adminData, csmData, customers, docCategories, apiCall }) {
    const navigate = useNavigate();
    const [isDocCategoryModelOpen, setIsDocCategoryModalOpen] = useState(false);
    const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);

    const userRole = getLocalStorageItem(LOCAL_STORAGE.ROLE_CODE);

    const cardConfigurations = [
        {
            heading: DASHBOARD_COLUMNS.ADMIN,
            data: adminData,
            view: ROUTES.ADMIN.ADMIN,
            showAddButton: false,
            onClickNext: (record) => {
                navigate(ROUTES.ADMIN.EDIT_PROFILE, {
                    state: { userId: record.id, adminData: true },
                });
            },
        },
        {
            heading: DASHBOARD_COLUMNS.CSM,
            data: csmData,
            view: ROUTES.ADMIN.CSM,
            showAddButton: false,
            onClickNext: (record) => {
                navigate(ROUTES.ADMIN.EDIT_PROFILE, { state: { userId: record.id } });
            },
        },
        {
            heading: DASHBOARD_COLUMNS.CUSTOMER,
            data: customers,
            view: ROUTES.ADMIN.CUSTOMER,
            showAddButton: userRole === ROLES.CSM ? false : true,
            onClickAdd: () => setIsCustomerModalOpen(true),
            onClickNext: (record) => {
                navigate(ROUTES.ADMIN.EDIT_PROFILE, {
                    state: { customerId: record.id, customerProfile: true },
                });
            },
        },
        {
            heading: DASHBOARD_COLUMNS.DOCUMENT_CATEGORY,
            data: docCategories,
            view: ROUTES.ADMIN.DOCUMENT_CATEGORY,
            showAddButton: true,
            onClickAdd: () => setIsDocCategoryModalOpen(true),
            onClickNext: (record) => {
                navigate(ROUTES.ADMIN.DOCUMENT_CATEGORY, { state: { id: record.id } });
            },
        },
    ];
    return (
        <>
            {cardConfigurations.map((config, index) => (
                <React.Fragment key={index}>
                    <div key={index} className='col-md-6 custom-col mb-lg-4 mb-2'>
                        <div className='h-100 card-container ssc-shadow d-flex flex-column w-100 align-items-center p-lg-4 p-3'>
                            <CardHead
                                onClickShowAddButton={config.onClickAdd}
                                showAddButton={config.showAddButton || false}
                                title={config.heading}
                            />
                            <div className='content-set-area flex-column d-flex w-100 align-items-start justify-content-start'>
                                {config.data.length
                                    ? config.data.map((record, index) => {
                                          if (index < 5) {
                                              return (
                                                  <NameBox
                                                      key={index}
                                                      name={record.full_name ?? record.name}
                                                      mail={record.email ?? ''}
                                                      onClickNext={() => config.onClickNext(record)}
                                                  />
                                              );
                                          }
                                      })
                                    : `${config.heading} not found`}
                            </div>
                            {config.data.length > 4 && (
                                <div className='view-btn d-flex justify-content-start w-100'>
                                    <ViewAll
                                        className='ssc-card-btn2 text-decoration-none'
                                        navigateTo={config.view}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    {isDocCategoryModelOpen && (
                        <DocumentCategoryAddEdit
                            openEditModal={isDocCategoryModelOpen}
                            setOpenEditModal={setIsDocCategoryModalOpen}
                            isEdit={false}
                            debouncedApiCall={apiCall}
                        />
                    )}

                    {isCustomerModalOpen && (
                        <AddNewCustomer
                            debouncedApiCall={apiCall}
                            showAddModal={isCustomerModalOpen}
                            setShowAddModal={setIsCustomerModalOpen}
                        />
                    )}
                </React.Fragment>
            ))}
        </>
    );
}
Card.propTypes = {
    adminData: PropTypes.array,
    csmData: PropTypes.array,
    customers: PropTypes.array,
    docCategories: PropTypes.array,
    apiCall: PropTypes.func,
};
