import React, { useEffect, useState } from 'react';
import DocViewer, { BMPRenderer, DocViewerRenderers, GIFRenderer } from '@cyntler/react-doc-viewer';
import { useDispatch, useSelector } from 'react-redux';

import { ButtonComponent } from '../../shared';
import { setLoaderVisibility, setFileViewModalDetails } from '../../../redux';
import { popup } from '../../../utils';
import { FILE_VIEWER } from '../../../constants';

export const FileViewerModal = () => {
    // declarations

    const dispatch = useDispatch();
    const signedURL = useSelector((state) => state.fileViewerModal.signedURL);
    const docs = useSelector((state) => state.fileViewerModal.docs);
    const openFileViewerModal = useSelector((state) => state.fileViewerModal.openFileViewerModal);
    const fileName = useSelector((state) => state.fileViewerModal.fileName);
    const [blobURL, setBlobURL] = useState('');
    const [extn, setExtn] = useState('');
    const [googleViewerURL, setGoogleViewerURL] = useState('');
    const [officeViewerURL, setOfficeViewerURL] = useState('');

    // functions

    const getBlobFromSignedUrl = async (signedUrl) => {
        dispatch(setLoaderVisibility(true));
        try {
            const response = await fetch(signedUrl);
            if (!response.ok) {
                popup('error', FILE_VIEWER.FILE_FETCH_FAILED);
            }

            const blob = await response.blob();
            const blobURI = URL.createObjectURL(blob);
            return blobURI;
        } catch (error) {
            console.error(FILE_VIEWER.FILE_FETCH_ERROR, error);
            popup('error', FILE_VIEWER.FILE_FETCH_ERROR);
            return null;
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    };

    const getFileExtension = (filename) => {
        const match = filename.match(/\.([^.?]+)(?:\?|$)/);
        return match ? match[1].toLowerCase() : '';
    };

    const getRendererForFileType = (extension) => {
        switch (extension) {
            case 'bmp':
                return BMPRenderer;
            case 'gif':
                return GIFRenderer;
            default:
                return null;
        }
    };

    const renderViewer = () => {
        // for -> 'mp4', 'gif', 'png', 'bmp', 'html', 'jpg', 'jpeg'
        const Renderer = getRendererForFileType(extn);
        const config = {
            header: {
                disableHeader: true,
            },
        };
        if (blobURL) {
            return (
                <>
                    {Renderer ? (
                        <DocViewer
                            documents={[{ uri: blobURL }]}
                            pluginRenderers={[Renderer]}
                            config={config}
                            className='file-viewer-doc-height'
                        />
                    ) : (
                        <DocViewer // pluginRenders are not required for these file formats -> for html, jpeg, jpg, mp4, png
                            documents={[{ uri: blobURL }]}
                            config={config}
                            className='file-viewer-doc-height'
                        />
                    )}
                </>
            );
        } else if (googleViewerURL || officeViewerURL) {
            const officeRenderURL =
                FILE_VIEWER.OFFICE_VIEWER_URL + encodeURIComponent(officeViewerURL); // for -> 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'
            const googleRenderURL =
                FILE_VIEWER.GOOGLE_VIEWER_URL +
                encodeURIComponent(googleViewerURL) +
                FILE_VIEWER.EMBEDED; // for -> 'tif', 'tiff', 'csv', 'pdf', 'txt'

            const renderURL = googleViewerURL ? googleRenderURL : officeRenderURL;

            return (
                <>
                    <object data={renderURL} className='external-viewer-url' />
                </>
            );
        } else if (docs?.length > 0) {
            const fileType = docs[0]?.fileType;
            if (
                fileType.startsWith('image/') ||
                fileType.startsWith('video/') ||
                fileType === 'application/vnd.ms-excel' ||
                fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                fileType === 'application/msword' ||
                fileType ===
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            ) {
                return (
                    <DocViewer
                        pluginRenderers={DocViewerRenderers}
                        documents={docs}
                        config={config}
                        className='file-viewer-doc-height'
                    />
                );
            } else if (fileType === 'application/pdf') {
                return <iframe src={docs[0].uri} width='100%' height='90%' />;
            } else {
                return <p>Cannot preview this file type. Download to view.</p>;
            }
        } else {
            return <div>{FILE_VIEWER.LOADING}</div>;
        }
    };

    // Initial state

    useEffect(() => {
        if (signedURL) {
            const extension = getFileExtension(signedURL);
            setExtn(extension);

            if (FILE_VIEWER.AVAILABLE_DOC_VIEWER_EXTNS.includes(extension)) {
                getBlobFromSignedUrl(signedURL).then((blobURI) => {
                    setBlobURL(blobURI);
                });
            } else if (FILE_VIEWER.AVAILABLE_GOOGLE_VIEWER_EXTNS.includes(extension)) {
                setGoogleViewerURL(signedURL);
            } else {
                setOfficeViewerURL(signedURL);
            }
        }
    }, [signedURL]);

    return (
        <div
            className={`modal custom-modal-doc-cato fade z-index-9999 show`}
            tabIndex={-1}
            aria-labelledby='exampleModalLabel'
            aria-hidden={!openFileViewerModal}
            role='dialog'
            style={{ display: 'block' }}
        >
            <div className='modal-dialog w-100'>
                <div className='modal-content border-0 custom-modal-size'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='head-title-edit' />
                        <ButtonComponent
                            clickHandler={() => {
                                dispatch(
                                    setFileViewModalDetails({
                                        openFileViewerModal: false,
                                        signedURL: '',
                                        fileName: '',
                                    }),
                                );
                            }}
                            className='btn-close'
                        ></ButtonComponent>
                    </div>

                    <div className='file-viewer-height-75vh'>
                        <div className='file-viewer-file-name-header'>{fileName}</div>
                        {renderViewer()}
                    </div>
                </div>
            </div>
        </div>
    );
};
