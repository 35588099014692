import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AdminPageHeader } from '../Common/AdminPagesHeader';
import { CUSTOMER_LIST, CUSTOMER_COLUMNS,ROLES, LOCAL_STORAGE,  } from '../../../constants';
import { Table, AddNewCustomer } from '../../shared';
import { usePagination, useDebounce } from '../../../hooks';
import { getAllAccounts, popup,getLocalStorageItem } from '../../../utils';
import { setLoaderVisibility } from '../../../redux';

export const CustomerListing = () => {
    const dispatch = useDispatch();
    const [response, setResponse] = useState({ data: [], count: 0 });
    const [search, setSearch] = useState('');
    const pagination = usePagination(response.data.length, response.count);
    const [showAddModal, setShowAddModal] = useState(false);

    const userRole = getLocalStorageItem(LOCAL_STORAGE.ROLE_CODE)

    const handleAddClick = () => {
        setShowAddModal(true);
    };

    const debouncedApiCall = useDebounce(() => {
        getAllAccounts({
            offset: pagination.offset,
            limit: pagination.limit,
            search: search,
            
        })
            .then((res) => {
                dispatch(setLoaderVisibility(false));
                if (res?.data) {
                    setResponse({ data: res.data, count: res.count });
                }
            })
            .catch((error) => {
                dispatch(setLoaderVisibility(false));
                popup('error', error.message);
            });
    }, 500);

    useEffect(() => {
        !search && dispatch(setLoaderVisibility(true));
        debouncedApiCall();
    }, [pagination.limit, pagination.offset, search]);
    return (
        <>
            <AdminPageHeader
                heading={CUSTOMER_LIST.PAGE_HEADING}
                buttonText={CUSTOMER_LIST.BUTTON_TEXT}
                searchBarPlaceholder={CUSTOMER_LIST.SEARCH_BAR_PLACEHOLDER}
                setSearch={setSearch}
                count={pagination.dataCount}
                showAddButton = {userRole === ROLES.ADMIN }
                handleAddClick={handleAddClick}
                AddModal={
                    showAddModal ? (
                        <AddNewCustomer
                            showAddModal={showAddModal}
                            setShowAddModal={setShowAddModal}
                            debouncedApiCall={debouncedApiCall}
                            />
                    ) : ''
                }
                setCurrentPage={pagination.setCurrentPage}
                backbutton
            />

            <Table
                columns={userRole === ROLES.ADMIN ? CUSTOMER_COLUMNS : CUSTOMER_COLUMNS.filter(columns => columns.name !== 'Created By')}
                data={response.data}
                pagination={pagination}
                pageName={CUSTOMER_LIST.PAGE_NAME}
                search={search}
                debouncedApiCall={debouncedApiCall}
            />
        </>
    );
};
