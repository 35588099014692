/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { DOCUMENTS, DOCUMENT_COLUMNS, ROUTES } from '../../../constants';
import { getAllDocumentRequest, popup } from '../../../utils';
import { AdminPageHeader } from '../Common/AdminPagesHeader';
import { usePagination, useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import { Table } from '../../shared';

export const DocumentListing = () => {
    const [response, setResponse] = useState({ data: [], count: 0 });
    const [filter, setFilter] = useState({priority: '', status: ''});
    const [selectedFilterNames, setSelectedFilterNames] = useState({ priority: 'All Priorities', status: 'All Statuses' });
    const [isFilterOpen, setIsFilterOpen] = useState({ priority: false, status: false });
    const [search, setSearch] = useState('');
    const pagination = usePagination(response.data.length, response.count);

    const filterConfig = [
        {
            name: 'priorityFilter',
            value: filter.priority,
            selectedFilterName: selectedFilterNames.priority,
            handleFunction: (value, name) => {
                setFilter({...filter, priority: value});
                setSelectedFilterNames({...selectedFilterNames, priority: name});
            },
            isFilterOpen: isFilterOpen.priority,
            setIsFilterOpen: (value) => setIsFilterOpen((prev)=> ({ ...prev, priority: value})),
            options:[{ name: 'All Priorities', value: '' },  { name: 'High', value: 'high' },  { name: 'Low', value: 'low' }]
        },
        {
            name: 'statusFilter',
            value: filter.status,
            selectedFilterName: selectedFilterNames.status,
            handleFunction: (value, name) => { setFilter({...filter, status: value});  setSelectedFilterNames({...selectedFilterNames, status: name}); },
            isFilterOpen: isFilterOpen.status,
            setIsFilterOpen: (value) => setIsFilterOpen((prev)=> ({ ...prev, status: value})),
            options:[{ name: 'All Statuses', value: '' }, { name: 'Approved', value: 'approved' }, { name: 'Rejected', value: 'rejected' }, { name: 'Pending', value: 'pending' }, { name: 'Requested', value: 'requested' }]
        }
    ]

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const debouncedApiCall = useDebounce(() => {
        getAllDocumentRequest({
            offset: pagination.offset,
            limit: pagination.limit,
            search: search,
            filter
        })
            .then((res) => {
                dispatch(setLoaderVisibility(false));
                if (res?.data) {
                    setResponse({ data: res.data, count: res.count });
                }
            })
            .catch((error) => {
                dispatch(setLoaderVisibility(false));
                popup('error', error.message);
            });
    }, 500);

    useEffect(() => {
        !search && dispatch(setLoaderVisibility(true));
        debouncedApiCall();
    }, [pagination.limit, pagination.offset, search, filter]);

    return (
        <>
            <AdminPageHeader
                heading={DOCUMENTS.PAGE_HEADING}
                buttonText={DOCUMENTS.BUTTON_TEXT}
                searchBarPlaceholder={DOCUMENTS.SEARCH_BAR_PLACEHOLDER}
                setSearch={setSearch}
                count={pagination.dataCount}
                showAddButton
                handleAddClick={()=>navigate(ROUTES.ADMIN.DOCUMENT_REQUEST)}
                backbutton
                setCurrentPage={pagination.setCurrentPage}
                filterConfig={filterConfig}
            />

            <Table
                columns={DOCUMENT_COLUMNS}
                data={response.data}
                pagination={pagination}
                pageName={DOCUMENTS.PAGE_NAME}
                debouncedApiCall={debouncedApiCall}
                search={search}
            />
        </>
    );
};
