import React from 'react';
import PropTypes from 'prop-types';

import { DELETE_MODAL } from '../../../constants';

export const DeleteConfirmationModal = ({
    isDeleteModal,
    setIsDeleteModal,
    selectedItemId,
    handleDeleteClick,
    isDisabled,
    deleteButtonName,
}) => {
    return (
        <div
            className={`modal custom-modal-doc-cato fade ${isDeleteModal ? 'show' : ''}`}
            tabIndex={-1}
            aria-labelledby='exampleModalLabel'
            aria-hidden={!isDeleteModal}
            role='dialog'
            style={{ display: isDeleteModal ? 'block' : 'none' }}
        >
            <div className='modal-dialog w-100'>
                <div className='modal-content border-0'>
                    <div className='d-flex align-items-center justify-content-end'>
                        <button
                            type='button'
                            className='btn-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsDeleteModal(false);
                            }}
                        />
                    </div>
                    <div className='modal-body p-0'>
                        <h3 className='text-center del-title mt-0'>
                            {`${DELETE_MODAL.DELETE_HEADING} ${deleteButtonName}`}
                        </h3>
                        <p className='text-center del-para mt-3 mb-4'>
                            {DELETE_MODAL.PARA_TEXT}
                            <br />
                            {DELETE_MODAL.UNDO_TEXT}
                        </p>
                    </div>
                    <div className='d-flex align-items-center justify-content-center modal-btn'>
                        <button
                            type='button'
                            className='ssc-secondary-white-btn '
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteClick({ selectedItemId, setIsDeleteModal });
                            }}
                            disabled={isDisabled}
                        >
                            {DELETE_MODAL.DELETE_BUTTON}
                        </button>
                        <button
                            type='button'
                            className='ssc-primary-green-btn py-0 w-auto'
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsDeleteModal(false);
                            }}
                        >
                            {'Cancel'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

DeleteConfirmationModal.propTypes = {
    isDeleteModal: PropTypes.bool,
    setIsDeleteModal: PropTypes.func,
    selectedItemId: PropTypes.string,
    handleDeleteClick: PropTypes.func,
    isDisabled: PropTypes.bool,
    deleteButtonName: PropTypes.string.isRequired,
};
