/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import OverView from './OverVIew';
import Card from './Card';
import { setLoaderVisibility } from '../../../redux';
import { popup, getAllAccounts, getAllDocumentCategory, getAllAdmin, getAllCSM, handleScroll } from '../../../utils';

export const DashboardContent = () => {
    const dispatch = useDispatch();
    const [adminData, setAdminData] = useState([]);
    const [csmData, setCsmData] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [docCategories, setDocCategories] = useState([]);

    const [isScrolling, setIsScrolling] = useState(false);
    const scrollBar = useRef(null);

    const fetchData = async () => {
        try {
            dispatch(setLoaderVisibility(true));
            const offset = 1, limit = 5;

            const [adminResponse, csmResponse, customersResponse, docCategoriesResponse] =
                await Promise.all([
                    getAllAdmin({ offset, limit }),
                    getAllCSM({ offset, limit }),
                    getAllAccounts({ offset, limit }),
                    getAllDocumentCategory({ offset, limit }),
                ]).catch((error) => {
                    throw error;
                });

            if (adminResponse?.data) setAdminData(adminResponse.data);
            if (csmResponse?.data) setCsmData(csmResponse.data);
            if (customersResponse?.data) setCustomers(customersResponse.data);
            if (docCategoriesResponse?.data) setDocCategories(docCategoriesResponse.data);
            return dispatch(setLoaderVisibility(false));
        } catch (error) {
            dispatch(setLoaderVisibility(false));
            popup('error', error.message);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleScrolling = () => {
        handleScroll(setIsScrolling, scrollBar, 2000);
    };

    return (
        // TODO: Need to correct the below class spelling when changed in the CSS
        <div onScroll={handleScrolling} onMouseMove={handleScrolling} className={`dashbaord-view-scroll pe-2 ${isScrolling ? 'scrolling' : 'not-scrolling'}`}>
            <OverView />
            <div className='dashboard-main-content mt-lg-4 mt-3'>
                <div className='row'>
                    <Card
                        adminData={adminData}
                        csmData={csmData}
                        customers={customers}
                        docCategories={docCategories}
                        apiCall={fetchData}
                    />
                </div>
            </div>
        </div>
    );
};
