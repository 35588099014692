export const formatPhoneToUSFormat = (phoneNumber) => {
    // Remove all non-digit characters
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');

    // Check if the cleaned number has the correct length
    if (cleaned.length === 10) {
        // Format the number as (xxx) xxx-xxxx
        return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
    }

    // If it's not a 10-digit number, return the original or a message
    return phoneNumber;
};
