import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Edit } from '../../../assets/svg';
import { ROLES } from '../../../constants';
import { useSelector } from 'react-redux';

export const EditButton = ({ id, navigateTo }) => {
    const navigate = useNavigate();
    const user = useSelector((s) => s.user);
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        if ([ROLES.ADMIN, ROLES.CSM].includes(user.role)) {
            setShowButton(true);
        }
    }, [user.role]);

    return showButton ? (
        <button
            className='border-0 p-0 bg-white'
            onClick={() => {
                navigate(navigateTo, {
                    state: {
                        id: id,
                    },
                });
            }}
            id='action-btn'
        >
            <Edit />
        </button>
    ) : (
        '-'
    );
};
EditButton.propTypes = {
    id: PropTypes.string,
    navigateTo: PropTypes.string,
};
