import React from 'react';
import PropTypes from 'prop-types';

export function TextArea({ label, placeholder, note, setNote, className, rows = 4, ...props }) {
    return (
        <div className='mb-4 w-100'>
            <h5>{label}</h5>
            <textarea
                onChange={(e) => setNote(e.target.value)}
                name=''
                id=''
                cols='10'
                rows={rows}
                className={className || 'form-control'}
                value={note}
                placeholder={placeholder}
                {...props}
            ></textarea>
        </div>
    );
}

TextArea.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    note: PropTypes.string,
    setNote: PropTypes.func,
    rows: PropTypes.number,
};
