import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { CustomDropdownArrow } from '../../../assets/svg';

export const CustomDropdown = ({
    selectedOption,
    options,
    setIsDropdownOpen,
    isDropdownOpen,
    handleOptionSelect,
}) => {
    const dropdownRef = useRef(null);
    const handleClickOutside = useCallback(
        (event) => {
            if (
                isDropdownOpen &&
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsDropdownOpen(false);
            }
        },
        [isDropdownOpen, setIsDropdownOpen],
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <div ref={dropdownRef} className='ms-4 customized-dropdown'>
            <div className='dropdown'>
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsDropdownOpen(!isDropdownOpen);
                    }}
                    className='custom-btn height-40px bg-white dropdown-toggle d-flex align-items-center justify-content-between'
                    type='button'
                    id='dropdownMenuButton1'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                >
                    {selectedOption}
                    <CustomDropdownArrow />
                </button>
                <ul
                    className={`dropdown-menu custom-drop-content ${isDropdownOpen ? 'show' : ''}`}
                    aria-labelledby='dropdownMenuButton1'
                >
                    {options.map((option) => {
                        const clickHandler = () => handleOptionSelect(option);
                        return (
                            <li key={option.name} onClick={clickHandler} className='cursor-pointer'>
                                <a className='dropdown-item d-flex align-items-center justify-content-start'>
                                    <div className='text-txt'>
                                        <span>{option.name}</span>
                                    </div>
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

CustomDropdown.propTypes = {
    optionsKeyName: PropTypes.string,
    selectedOption: PropTypes.any,
    options: PropTypes.array,
    setIsDropdownOpen: PropTypes.func,
    isDropdownOpen: PropTypes.bool,
    handleOptionSelect: PropTypes.func,
};
