import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { REGEX, REGULATORY_ISSUE, VALIDATION } from '../../../constants';
import { ButtonComponent } from '../Buttons/ButtonComponent';
import { formValidator } from '../../../utils';

export const RegulatoryAddEdit = ({
    isModalOpen,
    setIsModalOpen,
    data,
    modalType,
    handleEditClick,
}) => {
    const [regulatoryIssue, setRegulatoryIssue] = useState({
        title: data?.title || '',
        content: data?.content || '',
    });
    const [dirtyFields, setDirtyFields] = useState({ title: false, content: false });
    const [validationError, setValidationError] = useState({
        title: '',
        content: '',
    });
    const inputRef = useRef(null);
    const textareaRef = useRef(null);

    const modalHeading =
        modalType === 'edit'
            ? REGULATORY_ISSUE.EDIT_REGULATORY_ISSUE
            : REGULATORY_ISSUE.ADD_REGULATORY_ISSUE;

    const validationSchema = {
        title: {
            regex: REGEX.REGULATORY_TITLE,
            message: VALIDATION.REGULATORY_TITLE,
            requiredMessage: VALIDATION.REGULATORY_TITLE_REQUIRED,
        },
        content: {
            regex: REGEX.REGULATORY_CONTENT,
            message: VALIDATION.REGULATORY_CONTENT,
            requiredMessage: VALIDATION.REGULATORY_CONTENT_REQUIRED,
        },
    };

    const isRegulatoryValid = () => {
        const errors = {};
        formValidator({ values: regulatoryIssue, errors, validationSchema });
        setValidationError({ ...errors });
        return errors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        isRegulatoryValid();
        setRegulatoryIssue((prevData) => ({ ...prevData, [name]: value }));
    };

    const resizeTextarea = () => {
        const textarea = textareaRef.current;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    useEffect(() => {
        if (textareaRef.current) {
            resizeTextarea();
        }
    }, [regulatoryIssue.content]);

    const handleClickSave = async () => {
        handleEditClick({ broadcast: false, regulatoryIssue });
    };

    const handleClickSaveAndBroadcast = async () => {
        handleEditClick({ broadcast: true, regulatoryIssue });
    };

    return (
        <div
            className={`modal custom-modal-doc-cato fade ${isModalOpen ? 'show' : ''}`}
            id='regulatory-edit'
            tabIndex={-1}
            aria-labelledby='exampleModalLabel'
            aria-hidden={!isModalOpen}
            style={{ display: isModalOpen ? 'block' : 'none' }}
        >
            <div className='modal-dialog w-100'>
                <div className='modal-content border-0'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='head-title-edit'>{modalHeading}</span>
                        <ButtonComponent
                            className='btn-close'
                            type='button'
                            clickHandler={() => setIsModalOpen(false)}
                        />
                    </div>
                    <div className='modal-body p-0 mt-32 mb-32'>
                        <input
                            autoFocus
                            type='text'
                            name='title'
                            value={regulatoryIssue.title}
                            className={`add-newsfeed-text w-100 ${validationError.title && dirtyFields.title ? 'border border-danger-subtle border-1' : ''}`}
                            ref={inputRef}
                            onChange={(e)=> {
                                setDirtyFields({ ...dirtyFields, title: true }); 
                                handleChange(e);
                            }}
                            placeholder={'Add title'}
                            defaultValue={''}
                            cols={20}
                            rows={6}
                            onFocus={() => {isRegulatoryValid();}}
                            onBlur={() => { setTimeout(() => {
                                setDirtyFields({ ...dirtyFields, title: true });
                            }, 100); }}
                        />
                        {validationError && dirtyFields.title && (
                            <p className='error-message'>{validationError.title}</p>
                        )}

                        <textarea
                            name='content'
                            id='content'
                            className={`add-newsfeed-text w-100 mt-4 ${validationError.content && dirtyFields.content ? 'border border-danger-subtle border-1' : ''}`}
                            placeholder='Add content'
                            value={regulatoryIssue.content}
                            ref={textareaRef}
                            onChange={(e)=> {
                                setDirtyFields({ ...dirtyFields, content: true }); 
                                handleChange(e);
                            }}
                            cols={20}
                            rows={6}
                            style={{ resize: 'none', overflow: 'hidden' }}
                            onFocus={() => {isRegulatoryValid();}}
                            onBlur={() => { setTimeout(() => {
                                setDirtyFields({ ...dirtyFields, content: true });
                            }, 100); }}
                        />
                        {validationError && dirtyFields.content && (
                            <p className='error-message mt-0'>{validationError.content}</p>
                        )}
                    </div>

                    <div className='d-flex align-items-center justify-content-end modal-btn'>
                        <ButtonComponent
                            type='button'
                            className='ssc-secondary-white-btn'
                            clickHandler={() => setIsModalOpen(false)}
                        >
                            Cancel
                        </ButtonComponent>
                        <ButtonComponent
                            type='button'
                            className='ssc-primary-green-btn py-0 w-auto'
                            clickHandler={handleClickSave}
                            disabled={
                                !regulatoryIssue.title ||
                                !regulatoryIssue.content ||
                                validationError.title ||
                                validationError.content
                            }
                        >
                            Save
                        </ButtonComponent>
                        <ButtonComponent
                            type='button'
                            className='ssc-primary-green-btn py-0 w-auto'
                            clickHandler={handleClickSaveAndBroadcast}
                            disabled={
                                !regulatoryIssue.title ||
                                !regulatoryIssue.content ||
                                validationError.title ||
                                validationError.content
                            }
                        >
                            Save & Broadcast
                        </ButtonComponent>
                    </div>
                </div>
            </div>
        </div>
    );
};

RegulatoryAddEdit.propTypes = {
    isModalOpen: PropTypes.bool,
    setIsModalOpen: PropTypes.func,
    data: PropTypes.object,
    debouncedApiCall: PropTypes.func,
    handleEditClick: PropTypes.func,
    modalType: PropTypes.string,
};
