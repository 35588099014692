// In your accountsArraySlice.js file
import { createSlice } from '@reduxjs/toolkit';
import { LOCAL_STORAGE, REDUX, ROLE_NAME, ROLES } from '../../constants';
import { setLocalStorageItem } from '../../utils';

const initialState = {
    accounts: [],
    selectedAccount: '',
    selectedAccountName: '',
};

const accountsArraySlice = createSlice({
    name: REDUX.slice.ACCOUNT_ARRAY,
    initialState,
    reducers: {
        setAccounts: (state, action) => {
            state.accounts = action.payload;
        },
        setSelectedAccounts: (state, action) => {
            setLocalStorageItem(LOCAL_STORAGE.CUSTOMER_ID, action.payload);

            const account = state.accounts.find(account => account.id === action.payload);
            setLocalStorageItem(LOCAL_STORAGE.ROLE_NAME, account.is_primary_user ? ROLE_NAME.PRIMARY_USER : ROLE_NAME.SECONDARY_USER);
            setLocalStorageItem(LOCAL_STORAGE.ROLE_CODE, account.is_primary_user ? ROLES.PRIMARY_USER : ROLES.SECONDARY_USER);
            const accountName = state.accounts.find(account => account.id === action.payload).name;
            state.selectedAccountName = accountName;
            state.selectedAccount = action.payload;
        },
        setSelectedAccountName: (state, action) => {
            const selectedAccount = state.accounts.find(account => account.id === action.payload);
            state.selectedAccountName = selectedAccount.name;
        }
    },
});

export const { setAccounts, setSelectedAccounts, setSelectedAccountName } = accountsArraySlice.actions;
export default accountsArraySlice.reducer;
